/*
 * @Descripttion: 默认选项
 * @Author: zhanghang
 * @Date: 2021-12-16 10:44:16
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-21 18:22:18
 */
export default {
  created() {
    if (this.isDesign && !this.metadata.dataSource.code) {
      if (this.ctrlType === 'dsf.asqquestionjudge') {
        this.metadata.dataSource.code = [{
          text: "正确",
          value: "1",
        },
        {
          text: "错误",
          value: "0",
        },
        ]
      } else {
        this.metadata.dataSource.code = this.getDefaultOptions();
      }

    }
  },
  methods: {
    // 设置默认选项
    getDefaultOptions() {
      return [{
        text: "选项1",
        value: "1",
      },
      {
        text: "选项2",
        value: "2",
      },
      ];
    },
  }
}