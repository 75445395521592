
import questionItemMetaData from "./questionItemMetaData";
import questionOrderItemMetaData from "./questionOrderItemMetaData";
import questionScoreItemMetaData from "./questionScoreItemMetaData";
import questionProportionItemData from "./questionProportionItemData";
import questionSignatureItemMetaData from "./questionSignatureItemMetaData";
import questionFillMetaData from "./questionFillMetaData";
dsf.metadata.add("question-item-meta-data",questionItemMetaData);
dsf.metadata.add("question-order-item-meta-data",questionOrderItemMetaData);
dsf.metadata.add("question-score-item-meta-data",questionScoreItemMetaData);
dsf.metadata.add("question-proporty-item-meta-data",questionProportionItemData);
dsf.metadata.add("question-signature-item-meta-data",questionSignatureItemMetaData);
dsf.metadata.add("question-fill-meta-data",questionFillMetaData);